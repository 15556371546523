import React, { useState } from "react";
import { calcWinner } from "../herlpers/calcWinner";
import Board from "./Board";

const Game = () => {
  const [xIsNext, setXIsNext] = React.useState(true);
  const [stepNumber, setStepNumber] = React.useState(0);
  const [history, setHistory] = useState<{ squares: string[] }[]>([
    { squares: [...Array(9)].map((_, i) => i.toString()) },
  ]);

  const handleClick = (i: number) => {
    const historyPoint = history.slice(0, stepNumber + 1);
    const current = historyPoint[historyPoint.length - 1];

    const squares = current.squares.slice();
    if (calcWinner(squares) === "X" || calcWinner(squares) === "O") {
      return;
    }
    if (squares[i] === "X" || squares[i] === "O") {
      alert("This square is already taken");
      return;
    }
    squares[i] = xIsNext ? "X" : "O";
    setHistory(historyPoint.concat([{ squares: squares }]));
    setStepNumber(historyPoint.length);
    setXIsNext(!xIsNext);
  };

  const jumpTo = (step: number) => {
    setStepNumber(step);
    setXIsNext(step % 2 === 0);
  };

  const current = history[stepNumber];
  const winner = calcWinner(current.squares);

  const moves = history.map((_step, move) => {
    const destination = move ? `Go to move #${move}` : "Go to start";
    return (
      <li key={move}>
        <button onClick={() => jumpTo(move)}>{destination}</button>
      </li>
    );
  });

  const status = winner
    ? `Winner: ${winner}`
    : `Next player: ${xIsNext ? "X" : "O"}`;

  return (
    <div className="game">
      <div className="game-board">
        <div className="status">{status}</div>
        <Board squares={current.squares} onClick={handleClick} />
      </div>
      <div className="game-info">
        <div>{status}</div>
        <ol>{moves}</ol>
      </div>
    </div>
  );
};

export default Game;
