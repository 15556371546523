import "./App.css";
import Game from "./components/Game";

export const App = () => {
  return (
    <>
      <Game />
    </>
  );
};

export default App;
